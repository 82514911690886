import React, { useState } from 'react';
import { Container, TextField, Button, Divider, Typography, Link, Box, Radio, RadioGroup, FormControlLabel, Checkbox, FormGroup, Select, MenuItem, InputLabel } from '@mui/material';
import emailjs from 'emailjs-com';  
//Création d'un compte EmailJS pour envoi des fichiers JSON
//Voici les infos du compte : Name Pharos Email ilyes.hadj@outlook.com Password pharos
//Voici les infos du service : Name Outlook ServiceID service_j1uckg6 TemplateID template_tue3cae Public Key Mp614mJUjLkEc9xmj

function App() {
  // Gestion des données du formulaire avec useState
  const [formData, setFormData] = useState({
    site_vitrine:'',
    one_page: '',
    companyName: '',
    url: '',
    otherOptionUrl: '',
    email: '',
    otherOptionEmail: '',
    backgroundColor : '',
    fontFamily: '',
    otherOptionFontFamily: '',
    headerTitle: '',
    headerSubtitle: '',
    aboutParagraph1: '',
    aboutWhy: '',
    servicesSubtilte: '',
    servicesItems: '',
    yesOptionServicesItems: '',
    servicesParagraph: '',
    yesOptionServicesParagraph: '',
    contactAdress: '',
    contactPhone: '',
    contactEmail: '',
    contactSchedule: '',
    contactNetworks: '',
    currentEmail: '',
    additionnalMessage: ''
  });

  // Gérer les sections sélectionnées avec les checkbox
  const [selectedSections, setSelectedSections] = useState({
    about: false,
    services: false,
    gallery: false,
    contact: false
  });

  // Affichage conditionnel basé sur une question
  const [showSeveralPages, setShowSeveralPages] = useState(false);
  const [showOtherOptionUrl, setShowOtherOptionUrl] = useState(false);
  const [showOtherOptionEmail, setShowOtherOptionEmail] = useState(false);
  const [showOtherOptionFontFamily, setShowOtherOptionFontFamily] = useState(false);
  const [showYesOptionServicesItems, setShowYesOptionServicesItems] = useState(false);
  const [showYesOptionServicesParagraph, setShowYesOptionServicesParagraph] = useState(false);

  // Gestion des changements de champ
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Affichage conditionnel du champ "Autre" si l'utilisateur choisit l'option "Autre"
    if (name === 'one_page' && value === 'no') {
      setShowSeveralPages(true);
    } else if (name === 'one_page') {
      setShowSeveralPages(false);
    }
    if (name === 'url' && value === 'other') {
      setShowOtherOptionUrl(true);
    } else if (name === 'url') {
      setShowOtherOptionUrl(false);
    }
    if (name === 'email' && value === 'other') {
      setShowOtherOptionEmail(true);
    } else if (name === 'email') {
      setShowOtherOptionEmail(false);
    }
    if (name === 'fontFamily' && value === 'other') {
      setShowOtherOptionFontFamily(true);
    } else if (name === 'fontFamily') {
      setShowOtherOptionFontFamily(false);
    }
    if (name === 'servicesItems' && value === 'yes') {
      setShowYesOptionServicesItems(true);
    } else if (name === 'servicesItems') {
      setShowYesOptionServicesItems(false);
    }
    if (name === 'servicesParagraph' && value === 'yes') {
      setShowYesOptionServicesParagraph(true);
    } else if (name === 'servicesParagraph') {
      setShowYesOptionServicesParagraph(false);
    }
  };

  // Gestion des changements dans les sections (Checkboxes)
  const handleSectionChange = (e) => {
    const { name, checked } = e.target;
    setSelectedSections((prevData) => ({
      ...prevData,
      [name]: checked
    }));
  };
  
  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    const combinedData = {
      ...formData,
      selectedSections: { ...selectedSections }
    };
    const jsonData = JSON.stringify(combinedData, null, 2);
    
    // Affiche les données en JSON dans la console
    console.log(jsonData);

    // Appelle la fonction pour envoyer les données par e-mail
    await sendEmail(jsonData);
    
    // Réinitialiser le formulaire
    setFormData({
      site_vitrine: '',
      one_page: '',
      companyName: '',
      url: '',
      otherOptionUrl: '',
      email: '',
      otherOptionEmail: '',
      backgroundColor: '',
      fontFamily: '',
      otherOptionFontFamily: '',
      headerTitle: '',
      headerSubtitle: '',
      aboutParagraph1: '',
      aboutWhy: '',
      servicesSubtilte: '',
      servicesItems: '',
      yesOptionServicesItems: '',
      servicesParagraph: '',
      yesOptionServicesParagraph: '',
      contactAdress: '',
      contactPhone: '',
      contactEmail: '',
      contactSchedule: '',
      contactNetworks: '',
      currentEmail: '',
      additionnalMessage: ''
    });
    setSelectedSections({
      about: false,
      services: false,
      gallery: false,
      contact: false
    });
  };

  // Fonction pour envoyer les données à un service d'e-mail
  const sendEmail = async (jsonData) => {
    const serviceId = 'service_j1uckg6';
    const templateId = 'template_tue3cae';
    const userId = 'Mp614mJUjLkEc9xmj';
  
    const templateParams = {
      from_site_vitrine : formData.site_vitrine,
      from_one_page : formData.one_page,
      from_companyName: formData.companyName,
      from_url: formData.url,
      from_otherOptionUrl : formData.otherOptionUrl,
      from_email: formData.email,
      from_otherOptionEmail: formData.otherOptionEmail,
      from_sectionAbout : selectedSections.about,
      from_sectionServices : selectedSections.services,
      from_sectionGallery : selectedSections.gallery,
      from_sectionContact : selectedSections.contact,
      from_backgroundColor : formData.backgroundColor,
      from_fontFamily: formData.otherFontFamily,
      from_headerTitle: formData.headerTitle,
      from_headerSubtitle: formData.headerSubtitle,
      from_additionnalMessage: formData.additionnalMessage,
      from_aboutParagraph1: formData.aboutParagraph1,
      from_aboutWhy: formData.aboutWhy,
      from_servicesSubtilte: formData.servicesSubtilte,
      from_servicesItems: formData.servicesItems,
      from_yesOptionServicesItems: formData.yesOptionServicesItems,
      from_servicesParagraph: formData.servicesParagraph,
      from_yesOptionServicesParagraph: formData.yesOptionServicesParagraph,
      from_contactAdress: formData.contactAdress,
      from_contactPhone: formData.contactPhone,
      from_contactEmail: formData.contactEmail,
      from_contactSchedule: formData.contactSchedule,
      from_contactNetworks: formData.contactNetworks,
      from_currentEmail: formData.currentEmail,
      json_data: jsonData, // Inclure les données en JSON dans l'e-mail
    };
  
    try {
      await emailjs.send(serviceId, templateId, templateParams, userId);
      console.log('Email envoyé avec succès !');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        Formulaire de contact
      </Typography>
      <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Tous les champs du formulaire sont obligatoires. S'il y a une question à laquelle vous ne voulez pas (ou ne pouvez pas répondre), indiquez le (ex : "RAS", "Pas d'information sur cela"...). Nous le prendrons en compte.</Typography>

      <Divider sx={{ marginTop: 2 }}/>

      <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
        Vérification
      </Typography>
      <form onSubmit={handleSubmit}>

        {/* QCM site vitrine */}
         <Box mb={2}>
          <Typography variant="body1">Ce formulaire vous permettra de générer votre site vitrine : nous ne générons pas de site avec moyen de paiement inclus pour faire du ecommerce. Avez-vous bien l'intention de créer un site vitrine sans moyen de paiement ?</Typography>
          <RadioGroup name="site_vitrine" value={formData.site_vitrine} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Oui" />
            <FormControlLabel value="no" control={<Radio />} label="Non" />
          </RadioGroup>
        </Box>

        <Divider sx={{ marginTop: 2 }}/>

        <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
          Structure du site 
        </Typography>

        {/* QCM one page */}
        <Box mb={2}>
          <Typography variant="body1">Souhaitez-vous que votre site contienne une page ou plusieurs pages ? Ci-dessous deux exemples pour vous aider dans votre choix :</Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            Exemple {' '}
            <Link href="https://www.pharostest.com/" target="_blank" rel="noopener noreferrer">
              une page
            </Link>{' '}
          </Typography>
          <Typography variant="body2"sx={{ fontStyle: 'italic', marginBottom: 2 }}>
            Exemple {' '}
            <Link href="https://www.pharostest4.com/" target="_blank" rel="noopener noreferrer">
              plusieurs page
            </Link>{' '}
          </Typography>
          <RadioGroup name="one_page" value={formData.one_page} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Une page" />
            <FormControlLabel value="no" control={<Radio />} label="Plusieurs pages" />
          </RadioGroup>
        </Box>
        <Box mb={2}>
        <Typography variant="body1">Quel est ou quel sera le nom de votre marque/société ?</Typography>
          <TextField
            //label="Nom"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            fullWidth
            required
          />
        </Box>
        <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Nous vous demanderons de nous partager le logo de votre marque/société (si vous en avez un) après réception de votre formulaire.</Typography>


        {/* QCM url avec une option "Autre" */}
        <Box mb={2}>
          <Typography variant="body1">Quelle url (adresse internet) souhaitez-vous pour votre site internet ?</Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Sous réserve de disponibilité des adresses ci-dessous. Le cas échéant, des alternatives vous seront proposées.</Typography>          
          <RadioGroup name="url" value={formData.url} onChange={handleChange}>
            <FormControlLabel value=".fr" control={<Radio />} label="https://www.le-nom-de-votre-société.fr" />
            <FormControlLabel value=".com" control={<Radio />} label="https://www.le-nom-de-votre-société.com" />
            <FormControlLabel value="other" control={<Radio />} label="Autre" />
          </RadioGroup>
          {showOtherOptionUrl && (
            <TextField
              label="Si Autre, précisez"
              name="otherOptionUrl"
              value={formData.otherOptionUrl}
              onChange={handleChange}
              fullWidth
            />
          )}
        </Box>

        {/* QCM email avec une option "Autre" */}
        <Box mb={2}>
          <Typography variant="body1">Quelle adresse email professionnelle souhaitez-vous pour votre site internet ?</Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Sous réserve de disponibilité des adresses ci-dessous. Le cas échéant, des alternatives vous seront proposées.</Typography>          
          <RadioGroup name="email" value={formData.email} onChange={handleChange}>
            <FormControlLabel value=".fr" control={<Radio />} label="contact@le-nom-de-votre-société.fr" />
            <FormControlLabel value=".com" control={<Radio />} label="contact@le-nom-de-votre-société.com" />
            <FormControlLabel value="other" control={<Radio />} label="Autre" />
          </RadioGroup>
          {showOtherOptionEmail && (
            <TextField
              label="Si Autre, précisez"
              name="otherOptionEmail"
              value={formData.otherOptionEmail}
              onChange={handleChange}
              fullWidth
            />
          )}
        </Box>

        {/* Section Checkboxes pour choisir quelles sections afficher */}
        <FormGroup>
          <Typography variant="body1">Choisissez les sections que vous souhaitez voir apparaitre sur votre site internet :</Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Une section "Accueil" est prévue dans tous les cas.</Typography>
          <FormControlLabel
            control={<Checkbox checked={selectedSections.about} onChange={handleSectionChange} name="about" />}
            label="A propos"
          />
          <FormControlLabel
            control={<Checkbox checked={selectedSections.services} onChange={handleSectionChange} name="services" />}
            label="Services"
          />
          <FormControlLabel
            control={<Checkbox checked={selectedSections.gallery} onChange={handleSectionChange} name="gallery" />}
            label="Galerie"
          />
          <FormControlLabel
            control={<Checkbox checked={selectedSections.contact} onChange={handleSectionChange} name="contact" />}
            label="Contact"
          />
        </FormGroup>

        <Divider sx={{ marginTop: 2 }}/>
        
        <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
          Style du site 
        </Typography>

        {/* Couleur principale */}
        <Box mb={2}>
        <Typography variant="body1">Quelle couleur principale souhaitez-vous pour votre site internet ?</Typography>
        <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>
          Vous pouvez mettre une réponse en français comme "bleu" mais l'idéal serait de nous indiquer le code hexadécimal exact de la couleur que vous souhaitez. Vous pouvez le trouver sur {' '}
          <Link href="https://htmlcolorcodes.com/fr/" target="_blank" rel="noopener noreferrer">
            ce site
          </Link>{' '}
          (indiquez le code à 6 caractères que vous trouverez à côté de # sur le site).
        </Typography>
          <TextField
            label="Ex: 'bleu' ou '1A41D2'"
            name="backgroundColor"
            value={formData.backgroundColor}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            required
          />
        </Box>

        {/* QCM police avec une option "Autre" */}
        <Box mb={2}>
          <Typography variant="body1">Quelle police (format du texte) souhaitez-vous pour votre site internet ?</Typography>          
          <RadioGroup name="fontFamily" value={formData.fontFamily} onChange={handleChange}>
            <FormControlLabel value="Arial" control={<Radio />} label="Arial" sx={{ fontFamily: 'Arial, sans-serif' }} />
            <FormControlLabel value="Helvetica" control={<Radio />} label="Helvetica" sx={{ fontFamily: 'Helvetica, sans-serif' }} />
            <FormControlLabel value="Roboto" control={<Radio />} label="Roboto" sx={{ fontFamily: 'Roboto, sans-serif' }} />
            <FormControlLabel value="Open Sans" control={<Radio />} label="Open Sans" sx={{ fontFamily: 'Open Sans, sans-serif' }} />
            <FormControlLabel value="other" control={<Radio />} label="Autre" />
          </RadioGroup>
          {showOtherOptionFontFamily && (
            <TextField
              label="Si Autre, précisez (mettez le nom de votre police préféré sur Word)"
              name="otherOptionFontFamily"
              value={formData.otherOptionFontFamily}
              onChange={handleChange}
              fullWidth
            />
          )}
        </Box>

        {/*je ne mets pas de couleur secondaire pour l'instant*/}
        {/*je ne mets pas de couleur du texte pour l'instant*/}
        {/*je ne mets pas de langue pour l'instant*/}

        <Divider sx={{ marginTop: 2 }}/>
        
        <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
          Section 'Accueil'
        </Typography>

        <Box mb={2}>
        <Typography variant="body1">Quel titre souhaitez-vous pour la section 'Accueil' de votre site ?</Typography>
          <TextField
              name="headerTitle"
              value={formData.headerTitle}
              onChange={handleChange}
              fullWidth
              multiline
              rows={1}
              required
            />
        <img src="/ExempleAccueilTitre.png" alt="Exemple de titre section Accueil" style={{ width: '100%', marginTop: '6px', marginBottom: '16px' }} mt={1} />
        </Box>        

        <Box mb={2}>
        <Typography variant="body1">Quel sous-titre souhaitez-vous pour la section 'Accueil' de votre site ?</Typography>
          <TextField
              label= "Si vous n'en souhaitez pas , indiquez 'pas de sous-titre'"
              name="headerSubtitle"
              value={formData.headerSubtitle}
              onChange={handleChange}
              fullWidth
              multiline
              rows={2}
              required
            />
        <img src="/ExempleAccueilSousTitre.png" alt="Exemple de sous titre section Accueil" style={{ width: '100%', marginTop: '6px', marginBottom: '16px' }} />
        </Box>    

        <Box mb={2}>
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>Nous vous demanderons l'image que vous souhaitez mettre dans la section 'Accueil' après réception de votre formulaire.</Typography>
        </Box>  

        {/* Affichage dynamique des questions en fonction des sections sélectionnées */}
        
        {selectedSections.about && (
          <Box>
            <Divider sx={{ marginTop: 2 }}/>
            <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
              Section 'A propos'
            </Typography>
            <Box mb={2}>
              <Typography variant="body1">
                Quel paragraphe descriptif souhaitez-vous pour la section 'A propos' de votre site ?
              </Typography>
              <img src="/ExempleAproposParagraphe.png" alt="Exemple de paragraphe section A Propos" style={{ width: '100%', marginTop: '6px', marginBottom: '6px' }} mt={1} />
              <TextField
                label={
                  <>
                    Ecrivez le paragraphe ici.
                    <br />
                    Nous pouvons vous proposer un texte si vous préférez. Dans ce cas, indiquez nous les choses importantes à mettre en avant selon vous.
                  </>
                }
                name="aboutParagraph1"
                value={formData.aboutParagraph1}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                required
                sx={{
                  '& .MuiInputLabel-root': {
                    whiteSpace: 'normal',
                    lineHeight: '1.5',
                  },
                }}
              />
            </Box>
            {showSeveralPages && (
              <Box mb={2}>            
                <Typography variant="body1">Souhaitez-vous mettre en avant des éléments "pourquoi nous choisir" ?</Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Ces éléments seront affichés comme dans l'exemple ci-dessous.</Typography>          
                <img src="/ExemplePourquoiNousChoisir.png" alt="Exemple de carte de service" style={{ width: '100%', marginBottom: '6px' }} />
                <TextField
                  label= "Si Oui, précisez comme sur cet exemple : Réponse en 24h, Garantie 2 ans, etc. Si non, indiquez 'non'."
                  name="aboutWhy"
                  value={formData.aboutWhy}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={2}
                  required
                  slotProps={{
                    inputLabel: {
                      sx: {
                        fontSize: '0.875rem', // Ajuste la taille de la police du label
                        whiteSpace: 'pre-line', // Permet d'interpréter \n comme un retour à la ligne dans le label
                      },
                    },
                  }}
                />
              </Box>
            )}
            <Typography variant="body1" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Nous vous demanderons une image accompagnant la section 'A propos' après réception de votre formulaire.</Typography>
          </Box>
        )} 
        {selectedSections.services && (
          <Box>
            <Divider sx={{ marginTop: 2 }}/>
            <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
              Section 'Services'
            </Typography>
            <Box mb={2}>
              <Typography variant="body1">
                Quel sous-titre souhaitez-vous pour la section 'Services' de votre site ?
              </Typography>
              <TextField
                label= "Si vous n'en souhaitez pas , indiquez 'pas de sous-titre'"
                name="servicesSubtilte"
                value={formData.servicesSubtilte}
                onChange={handleChange}
                fullWidth
                multiline
                rows={2}
                required
              />
            </Box>
            <Box mb={2}>
              <Typography variant="body1">Souhaitez-vous mettre en avant certains éléments dans les services que proposent votre société ? </Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Ces éléments de services seront affichés dans des 'cartes' comme dans l'exemple ci-dessous.</Typography>          
              <img src="/ExempleCartesServices.png" alt="Exemple de carte de service" style={{ width: '100%', marginBottom: '0px' }} />
              <RadioGroup name="servicesItems" value={formData.servicesItems} onChange={handleChange}>
                <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                <FormControlLabel value="no" control={<Radio />} label="Non" />
              </RadioGroup>
              {showYesOptionServicesItems && (
                <TextField
                  label="Si Oui, précisez. Exemple : Installation, Dépanage, Pièces détachées."
                  name="yesOptionServicesItems"
                  value={formData.yesOptionServicesItems}
                  onChange={handleChange}
                  fullWidth
                />
              )}
            </Box>
            <Box mb={2}>
              <Typography variant="body1">Souhaitez-vous mettre un texte donnant des détails dans la section "Services" ? </Typography>
              <RadioGroup name="servicesParagraph" value={formData.servicesParagraph} onChange={handleChange}>
                <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                <FormControlLabel value="no" control={<Radio />} label="Non" />
              </RadioGroup>
              {showYesOptionServicesParagraph && (
                <TextField
                  label={
                    <>
                      Si Oui, écrivez le texte ici.
                      <br />
                      Nous pouvons vous proposer un texte si vous préférez. Dans ce cas, indiquez nous les choses importantes à mettre en avant selon vous.
                    </>
                  }
                  name="yesOptionServicesParagraph"
                  value={formData.yesOptionServicesParagraph}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    '& .MuiInputLabel-root': {
                      whiteSpace: 'normal',
                      lineHeight: '1.5',
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        )}
        {selectedSections.gallery && (
          <Box>
            <Divider sx={{ marginTop: 2 }}/>
            <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
              Section 'Galerie'
            </Typography>
            <Typography variant="body1" sx={{ fontStyle: 'italic', marginBottom: 2 }}>Nous vous demanderons les images que vous souhaitez mettre (autant d'images que vous voulez) dans la section 'Galerie' après réception de votre formulaire.</Typography>
            <img src="/ExempleGalerie.png" alt="Exemple section Galerie" style={{ width: '100%', marginTop: '6px', marginBottom: '6px' }} mt={1} />
          </Box>
        )}
        {selectedSections.contact && (
          <Box>
            <Divider sx={{ marginTop: 2 }}/>
            <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
              Section Contact
            </Typography>
            <Box mb={2}>
              <Typography variant="body1">
                Quelle adresse souhaitez-vous indiquer dans la section 'Contact' de votre site ?
              </Typography>
              <TextField
                name="contactAdress"
                value={formData.contactAdress}
                onChange={handleChange}
                fullWidth
                multiline
                rows={1}
                required
              />
              <img src="/ExempleContact.png" alt="Exemple section Contact" style={{ width: '100%', marginTop: '6px', marginBottom: '0px' }} mt={1} />
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                Quel numéro de téléphone souhaitez-vous indiquer dans la section 'Contact' de votre site ?
              </Typography>
              <TextField
                name="contactPhone"
                value={formData.contactPhone}
                onChange={handleChange}
                fullWidth
                multiline
                rows={1}
                required
              />
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                Quelle adresse email souhaitez-vous indiquer dans la section 'Contact' de votre site ?
              </Typography>
              <TextField
                name="contactEmail"
                value={formData.contactEmail}
                onChange={handleChange}
                fullWidth
                multiline
                rows={1}
                required
              />
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                Quels horaires souhaitez-vous indiquer dans la section 'Contact' de votre site ?
              </Typography>
              <TextField
                label="Ex: lundi à vendredi (9h-12h, 14h-18h), samedi (9h-12h)"
                name="contactSchedule"
                value={formData.contactSchedule}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                required
              />
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                Quels liens vers vos réseaux sociaux souhaitez-vous indiquer dans la section 'Contact' de votre site ?
              </Typography>
              <TextField
                label="lien1, lien2, lien3..."
                name="contactNetworks"
                value={formData.contactNetworks}
                onChange={handleChange}
                fullWidth
                multiline
                rows={2}
                required
              />
            </Box>
          </Box>
        )}

        <Divider sx={{ marginTop: 4 }}/>
        
        <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
          Derniers détails avant envoi du formulaire
        </Typography>

        <Typography variant="body2" sx={{ marginBottom: 2 }}>Nous arrivons à la fin du formulaire. Quand vous appuierez sur le bouton "Envoyer" ci-dessous, vos réponses disparaitront : ne vous inquiétez pas, cela voudra dire que nous aurons reçu vos réponses.</Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>Nous vous recontacterons alors dans les 48h par email pour finaliser la création de votre site internet. Nous vous demanderons notamment de nous fournir les différentes images que vous souhaitez voir incorporer (logo, image "Accueil" etc).</Typography>
        <Box mb={2}>
        <Typography variant="body1">Indiquez l'email avec lequel vous souhaitez échanger pour finaliser le site ?</Typography>
          <TextField
            //label="Message"
            name="currentEmail"
            value={formData.currentEmail}
            onChange={handleChange}
            fullWidth
          />
        </Box>
        <Box mb={2}>
        <Typography variant="body1">Y a-t-il un ou plusieurs éléments que vous souhaitez préciser avant envoi ?</Typography>
          <TextField
            //label="Message"
            name="additionnalMessage"
            value={formData.additionnalMessage}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
          />
        </Box>

        <Button variant="contained" color="primary" type="submit" sx={{ marginTop: 2, marginBottom: 4 }}>
          Envoyer
        </Button>
      </form>
    </Container>
  );
}

export default App;
